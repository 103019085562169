import React from "react";
import "./leftsidebutton.css";
import DarkMode from "../darkmode/Darkmode";

const LeftSideButton = ({ tolink, name, title }) => {
  return (
    <div className="leftside-link-main">
      <div className="leftside-link">
        <a href={tolink} title={title}>
          {name}
        </a>
      </div>
      <div className="rightside-link-darkmode">
        <DarkMode />
      </div>
    </div>
  );
};

export default LeftSideButton;
