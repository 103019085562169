import React from "react";

const Footer = () => {
  return (
    <div>
      <hr />
      <div id="footer">Thank you for visting my portfolio !</div>
    </div>
  );
};

export default Footer;
