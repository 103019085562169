import React from "react";

const About = () => {
  const aboutPic =
    "https://bootcampspot.com/broker/studentAvatar?accountId=102745";
  return (
    <div id="about">
      {/* <h2 className="heading">About Me</h2> */}
      <div id="aboutPic">
        {/* selfie update  */}
        {/* <img src={aboutPic} alt="Profile pic" /> */}
      </div>
      <div className="about-me-code-container">
        <div className="about-me-code-threedots">
          <div style={{ color: "red" }}>&#9679;</div>
          <div style={{ color: "goldenrod" }}>&#9679;</div>
          <div style={{ color: "limegreen" }}>&#9679;</div>
        </div>
        <div className="about-me-code-content">
          <p>
            <span className="about-me-code-const-name">function </span>
            <span className="about-me-code-function-name">about</span>() {"{"}
          </p>
          <p>
            <span className="about-me-code-const-name">const</span> name = "
            <span className="about-me-code-values">Rajveer Sidhu</span>";
          </p>
          <p>
            <span className="about-me-code-const-name">const</span> age ={" "}
            <span className="about-me-code-values">26</span>;
          </p>
          <p>
            <span className="about-me-code-const-name">const</span> skills = [
            <span className="about-me-code-values">
              "Javascript", "Reactjs", "Nodejs", "UI/UX", "SQL"
            </span>
            ];
          </p>
          <p>
            <span className="about-me-code-const-name">const</span> hobbies = [
            <span className="about-me-code-values">
              "Basketball", "Video Games", "Tearing down & rebuilding things"
            </span>
            ];
          </p>
          <br />
          <p>
            <span className="about-me-code-const-name">let</span> aboutMe = `
            <span className="about-me-code-values">Hey there My name is</span>
            <span className="about-me-code-const-name">{"${name}"},</span>
            <span className="about-me-code-values"> I am </span>{" "}
            <span className="about-me-code-const-name">{"${age}"}</span>
            <span className="about-me-code-values">
              years old coding enthusiast.
            </span>{" "}
            `;
          </p>
          <p>
            aboutme += `
            <span className="about-me-code-values">
              The skills i possess are
            </span>
            <span className="about-me-code-const-name">
              {'${skills.join(", ")}'}
            </span>
            <span className="about-me-code-values">
              & learning something new everyday.
            </span>{" "}
            `;
          </p>
          <p>
            aboutme += `
            <span className="about-me-code-values">
              When i am not coding you will find me
            </span>
            <span className="about-me-code-const-name">
              {'${hobbies.join(", ")}'}
            </span>
            <span className="about-me-code-values">.</span> `;
          </p>
          <p>
            aboutme += `
            <span className="about-me-code-values">
              So lets collaborate on some mind-blowing project.
            </span>{" "}
            `;
          </p>
          <br />
          <p>
            <span className="about-me-code-const-name">return</span> aboutMe;
            <br />
            {"}"}
          </p>
          <br />
          <p>
            <span className="about-me-code-function-name">console</span>.
            <span className="about-me-code-function-name">log</span>(
            <span className="about-me-code-function-name">about</span>());
          </p>
        </div>
      </div>

      {/* <p>
        I am Rajveer Singh, a full stack developer from Los Angeles, California.
        I love building responsive and functionality-focused websites and web
        applications. I discovered my passion for web development during the
        pandemic, and I haven't stopped coding since!
      </p> */}
    </div>
  );
};

export default About;

// make the font size smaller for macbook air
