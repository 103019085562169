import React from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import HeaderEffect from "../../components/typewriting/Typerwriting";
import image from "../../assets/images/hire.png";
import "./heroheader.css";

const amAnimate = {
  offScreen: { x: -200 },
  onScreen: { x: 0 },
  transition: {
    type: "spring",
    bouce: 0.9,
    duration: 2,
  },
};

const nameAnimate = {
  offScreen: { x: 2200 },
  onScreen: { x: 0 },
  transition: {
    type: "spring",
    bouce: 0.7,
    duration: 3,
  },
};

const HeroHeader = () => {
  const { scrollYProgress } = useScroll();
  const x = useTransform(scrollYProgress, [0, 1], [0, -2500]);

  let hireMeImage = image;

  return (
    <motion.div
      id="hero-header"
      initial={"offScreen"}
      animate={"onScreen"}
      transition={{ staggerChildren: 1 }}
    >
      <motion.div className="hero-header-who-am" variants={amAnimate}>
        <h3>Hello, I am </h3>
        <HeaderEffect img={hireMeImage} />
      </motion.div>
      <motion.h2
        style={{ x }}
        variants={nameAnimate}
        className="hero-header-my-name"
      >
        Rajveer Sidhu
      </motion.h2>
    </motion.div>
  );
};

export default HeroHeader;

// add a loader screen or finish the single page
