import React from "react";

const EachPage = () => {
  return <div className="each-page-container">EachPage</div>;
};

export default EachPage;

// Create a header and some photos

// why, how, when
