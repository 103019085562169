import "./App.css";
import AllPages from "./pages/AllPages";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Resume from "./components/Resume/Resume";
import EachPage from "./components/EachPage/EachPage";
import Error404Page from "./pages/Error404Page";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<AllPages />} />
          <Route path="/project/:id" element={<EachPage />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/*" element={<Error404Page />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
