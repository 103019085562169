import React from "react";
import { Link } from "react-router-dom";

const Error404Page = () => {
  return (
    <div className="error-page-wrapper">
      <div className="error-page">404 Error | This Page Deosn't Exist.</div>
      <Link to="/">
        <button className="button-50">Go Home</button>
      </Link>
    </div>
  );
};

export default Error404Page;
