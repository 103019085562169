import React from "react";

const Skills = () => {
  return (
    <div>
      <section id="skills">
        {/* <h2 className='heading'>Skills</h2> */}
        <div id="icons">
          <div>
            <i className="devicon-html5-plain-wordmark colored"></i>
          </div>
          <div>
            <i className="devicon-css3-plain-wordmark colored"></i>
          </div>
          <div>
            <i className="devicon-javascript-plain colored"></i>
          </div>
          <div>
            <i className="devicon-react-original-wordmark colored"></i>
          </div>
          <div>
            <i className="devicon-bootstrap-plain-wordmark colored"></i>
          </div>
          <div>
            <i className="devicon-git-plain-wordmark colored"></i>
          </div>
          <div>
            <i className="devicon-nodejs-plain-wordmark colored"></i>
          </div>
          <div>
            <i className="devicon-figma-plain colored"></i>
          </div>
          <div>
            <i className="devicon-github-original-wordmark colored"></i>
          </div>
          <div>
            <i className="devicon-graphql-plain-wordmark colored"></i>
          </div>
          <div>
            <i className="devicon-handlebars-plain-wordmark"></i>
          </div>
          <div>
            <i className="devicon-heroku-plain-wordmark colored"></i>
          </div>
          <div>
            <i className="devicon-jquery-plain-wordmark colored"></i>
          </div>
          <div>
            <i className="devicon-mongodb-plain-wordmark colored"></i>
          </div>
          <div>
            <i className="devicon-mysql-plain-wordmark colored"></i>
          </div>
          <div>
            <i className="devicon-sequelize-plain-wordmark colored"></i>
          </div>
          <div>
            <i className="devicon-slack-plain-wordmark colored"></i>
          </div>
          <div>
            <i className="devicon-vscode-plain-wordmark colored"></i>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Skills;
