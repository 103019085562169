import React from "react";
import "./Project.css";
import keyChange from "../../static/assets/images/Key-Change.png";
import project1 from "../../static/assets/images/project-1.png";
import getOutside from "../../static/assets/images/mockup.jpg";

const Project = () => {
  return (
    <div className="project" id="work">
      {/* <h2 className="heading">Projects</h2> */}

      {/* Desktop Projects */}
      <section className="project__section">
        <div className="project__left">
          <img className="project__img" src={getOutside} alt="Get Outside" />
        </div>
        <div className="project__right">
          <h3 className="project__headingTertiary">Featured Project</h3>
          <a
            href="https://arcane-stream-21674.herokuapp.com/"
            target="_blank"
            rel="noreferrer"
            className="project__headingSecondary"
          >
            <h2>Get OutSide</h2>
          </a>
          <div className="project__descriptionContainer">
            <p className="project__description">
              Get Outside is a full stack MERN application with a front end
              built with React. It is fully mobile responsive.
            </p>
            <div className="project__tags">
              React.js &nbsp; Mongoose &nbsp; Node.js &nbsp; Material UI &nbsp;
              Express.js &nbsp; CSS &nbsp; GraphQL &nbsp; Bcrypt
            </div>
            <div className="project__icons">
              <a
                href="https://github.com/rajveer-s/get-outside"
                target="_blank"
                rel="noreferrer"
                style={{ marginRight: "1.6rem" }}
              >
                <svg className="project__icon">
                  <use href="icons/symbol-defs.svg#icon-github"></use>
                </svg>
              </a>
              <a
                href="https://arcane-stream-21674.herokuapp.com/"
                target="_blank"
                rel="noreferrer"
              >
                <svg className="project__icon">
                  <use href="icons/symbol-defs.svg#icon-external-link"></use>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="project__section">
        <div className="project__left1">
          <h3 className="project__headingTertiary">Featured Project</h3>
          <a
            href="https://xnd0.github.io/Are_We_There_Yet_Adventure_App/"
            target="_blank"
            rel="noreferrer"
            className="project__headingSecondary"
          >
            <h2>Are we there yet</h2>
          </a>
          <div className="project__descriptionContainer project__descriptionContainer1">
            <p className="project__description">
              Are we there yet app, where the user can fetch all the national
              parks in the U.S. with just a click of the button. User can also
              save their favorite parks which gets saved into the local storage.
              It also has a To-do list page where the user can write anything
              they would want to take on the trip.
            </p>
            <div className="project__tags">
              HTML/CSS &nbsp; Javascript &nbsp; Google API &nbsp; National State
              Park API
            </div>
            <div className="project__icons project__icons1">
              <a
                href="https://github.com/rajveer-s/Are_We_There_Yet_Adventure_App"
                target="_blank"
                rel="noreferrer"
                style={{ marginRight: "1.6rem" }}
              >
                <svg className="project__icon">
                  <use href="icons/symbol-defs.svg#icon-github"></use>
                </svg>
              </a>
              <a
                href="https://xnd0.github.io/Are_We_There_Yet_Adventure_App/"
                target="_blank"
                rel="noreferrer"
              >
                <svg className="project__icon">
                  <use href="icons/symbol-defs.svg#icon-external-link"></use>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="project__right">
          <img className="project__img" src={project1} alt="Are we there yet" />
        </div>
      </section>

      <section className="project__section project__section3">
        <div className="project__left">
          <img className="project__img" src={keyChange} alt="Key Change" />
        </div>
        <div className="project__right">
          <h3 className="project__headingTertiary">Featured Project</h3>
          <a
            href="https://thawing-fjord-72285.herokuapp.com/"
            target="_blank"
            rel="noreferrer"
            className="project__headingSecondary"
          >
            <h2>Key Change</h2>
          </a>
          <div className="project__descriptionContainer">
            <p className="project__description">
              This web application presents a centralized internet commerce hub
              for musicians to buy, sell, and auction instruments. Key Change
              allows users to submit their instrument name, description, image,
              and price that is then dynamically uploaded to our database and is
              added to our homepage.
            </p>
            <div className="project__tags">
              HTML/CSS &nbsp; Express.js &nbsp; Cloudinary &nbsp;
              Express-Handlebar &nbsp; MySql2 &nbsp; Sequelize
            </div>
            <div className="project__icons">
              <a
                href="https://github.com/rajveer-s/Key-Change"
                target="_blank"
                rel="noreferrer"
                style={{ marginRight: "1.6rem" }}
              >
                <svg className="project__icon">
                  <use href="icons/symbol-defs.svg#icon-github"></use>
                </svg>
              </a>
              <a
                href="https://thawing-fjord-72285.herokuapp.com/"
                target="_blank"
                rel="noreferrer"
              >
                <svg className="project__icon">
                  <use href="icons/symbol-defs.svg#icon-external-link"></use>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Mobile Projects */}
      <ul className="projectResp__list">
        <li className="projectResp__items projectResp__items1">
          <div className="projectResp__card">
            <div className="projectResp__cardTop">
              <svg className="projectResp__cardFolder">
                <use href="icons/symbol-defs.svg#icon-folder"></use>
              </svg>
              <div className="projectResp__cardLink">
                <a
                  href="https://github.com/rajveer-s/get-outside"
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginRight: "1.6rem" }}
                >
                  <svg className="projectResp__icon">
                    <use href="icons/symbol-defs.svg#icon-github"></use>
                  </svg>
                </a>
                <a
                  href="https://arcane-stream-21674.herokuapp.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg className="projectResp__icon">
                    <use href="icons/symbol-defs.svg#icon-external-link"></use>
                  </svg>
                </a>
              </div>
            </div>
            <div className="projectResp__cardBody">
              <div className="projectResp__cardBodyHeading">Get OutSide</div>
              <p className="projectResp__cardBodyDescription">
                Get Outside is a full stack MERN application with a front end
                built with React. It is fully mobile responsive.
              </p>
            </div>
            <div className="projectResp__cardFooter">
              <div className="projectResp__tags">
                React.js &nbsp; Mongoose &nbsp; Node.js &nbsp; Material UI
                &nbsp; Express.js &nbsp; CSS &nbsp; GraphQL &nbsp; Bcrypt
              </div>
            </div>
          </div>
        </li>
        <li className="projectResp__items projectResp__items2">
          <div className="projectResp__card">
            <div className="projectResp__cardTop">
              <svg className="projectResp__cardFolder">
                <use href="icons/symbol-defs.svg#icon-folder"></use>
              </svg>
              <div className="projectResp__cardLink">
                <a
                  href="https://github.com/rajveer-s/Are_We_There_Yet_Adventure_App"
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginRight: "1.6rem" }}
                >
                  <svg className="projectResp__icon">
                    <use href="icons/symbol-defs.svg#icon-github"></use>
                  </svg>
                </a>
                <a
                  href="https://xnd0.github.io/Are_We_There_Yet_Adventure_App/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg className="projectResp__icon">
                    <use href="icons/symbol-defs.svg#icon-external-link"></use>
                  </svg>
                </a>
              </div>
            </div>
            <div className="projectResp__cardBody">
              <div className="projectResp__cardBodyHeading">
                Are we there yet
              </div>
              <p className="projectResp__cardBodyDescription">
                Are we there yet app, where the user can fetch all the national
                parks in the U.S. with just a click of the button. User can also
                save their favorite parks which gets saved into the local
                storage. It also has a To-do list page where the user can write
                anything they would want to take on the trip.
              </p>
            </div>
            <div className="projectResp__cardFooter">
              <div className="projectResp__tags">
                HTML/CSS &nbsp; Javascript &nbsp; Google API &nbsp; National
                State Park API
              </div>
            </div>
          </div>
        </li>
        <li className="projectResp__items projectResp__items3">
          <div className="projectResp__card">
            <div className="projectResp__cardTop">
              <svg className="projectResp__cardFolder">
                <use href="icons/symbol-defs.svg#icon-folder"></use>
              </svg>
              <div className="projectResp__cardLink">
                <a
                  href="https://github.com/rajveer-s/Key-Change"
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginRight: "1.6rem" }}
                >
                  <svg className="projectResp__icon">
                    <use href="icons/symbol-defs.svg#icon-github"></use>
                  </svg>
                </a>
                <a
                  href="https://thawing-fjord-72285.herokuapp.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg className="projectResp__icon">
                    <use href="icons/symbol-defs.svg#icon-external-link"></use>
                  </svg>
                </a>
              </div>
            </div>
            <div className="projectResp__cardBody">
              <div className="projectResp__cardBodyHeading">Key Change</div>
              <p className="projectResp__cardBodyDescription">
                This web application presents a centralized internet commerce
                hub for musicians to buy, sell, and auction instruments. Key
                Change allows users to submit their instrument name,
                description, image, and price that is then dynamically uploaded
                to our database and is added to our homepage.
              </p>
            </div>
            <div className="projectResp__cardFooter">
              <div className="projectResp__tags">
                HTML/CSS &nbsp; Express.js &nbsp; Cloudinary &nbsp;
                Express-Handlebar &nbsp; MySql2 &nbsp; Sequelize
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Project;
