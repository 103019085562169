import React from "react";
import Footer from "../components/footer/Footer";
import LeftSideButton from "../components/leftSideButton/LeftSideButton";
import Navbar from "../components/navbar/Navbar";
import About from "./about/About";
import Contact from "./contact/Contact";
import HeroHeader from "./heroheader/HeroHeader";
import Skills from "./skills/Skills";
import { motion } from "framer-motion";
import ScrollToTop from "../components/scrollToTop/ScrollToTop";
import Project from "./Projects/Project";

const AllPages = () => {
  return (
    <>
      {/* <Navbar /> */}
      <LeftSideButton
        name="resume."
        title="Check out my Resume"
        tolink="/resume"
      />
      <motion.div
        initial={{ x: "-100%" }}
        animate={{ x: "0%" }}
        transition={{ duration: 1, ease: "anticipate" }}
        exit={{ opacity: 1 }}
      >
        <HeroHeader />
        <hr />
        <About />
        <hr />
        <Skills />
        <hr />
        <Project />
        <hr />
        <Contact />
        <Footer />
      </motion.div>
      <ScrollToTop />
    </>
  );
};

export default AllPages;
