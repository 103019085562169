import React from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";

const HeaderEffect = ({ img }) => {
  const { text } = useTypewriter({
    words: [
      "a Web Developer",
      "a Critical Thinker",
      "Creative",
      "a Team Player",
      "a Gamer",
    ],
    loop: true,
    delaySpeed: 2000,
    deleteSpeed: 90,
    typeSpeed: 100,
  });

  return (
    <div className="loopdiv">
      {/* <a href="#contact"><img src={img} alt="Hire me Logo" className='hire-me-img' /></a> */}
      <h5>
        {text} <Cursor />
      </h5>
    </div>
  );
};

export default HeaderEffect;
