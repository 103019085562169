import React, { useState, useEffect } from "react";
import "../../styles/DarkMode.css";
import { RiSunLine, RiMoonLine } from "react-icons/ri";

const DarkMode = () => {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const body = document.body;
  const lightTheme = "light";
  const darkTheme = "dark";

  useEffect(() => {
    body.classList.add(theme);
  }, [theme, body.classList]);

  const switchTheme = () => {
    if (theme === darkTheme) {
      body.classList.replace(darkTheme, lightTheme);
      localStorage.setItem("theme", lightTheme);
      setTheme(lightTheme);
    } else {
      body.classList.replace(lightTheme, darkTheme);
      localStorage.setItem("theme", darkTheme);
      setTheme(darkTheme);
    }
  };

  return (
    <div className="svg-color-mode" onClick={switchTheme}>
      {theme === darkTheme ? (
        <RiSunLine size={"3rem"} />
      ) : (
        <RiMoonLine size={"3rem"} color={"#000000"} />
      )}
    </div>
  );
};

export default DarkMode;
