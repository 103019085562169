import React, { useRef } from "react";
import "./contact.css";
import {
  RiMailLine,
  RiGithubLine,
  RiLinkedinLine,
  RiTwitterLine,
  RiPhoneLine,
  RiSendPlane2Line,
} from "react-icons/ri";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_pbh4496",
        "template_51pvr3g",
        form.current,
        "3DGevyUBS41e0qWg_"
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div id="contact">
      {/* <h2 className='heading'>Contact Me</h2> */}
      <div className="contact-containers">
        <div className="contact-container-1">
          <div className="contact-content">
            <h1>
              Feel free to reach out if <br />
              you wanna work <br />
              with me, <br />
              or just have a chat
            </h1>
          </div>
          <div className="contact-social-links">
            <div className="contact-row1">
              <a href="mailto:rajveersiidhu@gmail.com">
                <RiMailLine size={"2rem"} />
                Email
              </a>
              <a href="tel:+8184916746">
                <RiPhoneLine size={"2rem"} />
                +1 (818) 491-6746
              </a>
            </div>
            <div className="contact-row2">
              <a
                href="https://github.com/rajveer-s"
                target="_blank"
                rel="noreferrer"
              >
                <RiGithubLine size={"2rem"} />
                Github
              </a>
              <a
                href="https://www.linkedin.com/in/rajveer-s/"
                target="_blank"
                rel="noreferrer"
              >
                <RiLinkedinLine size={"2rem"} />
                LinkedIn
              </a>
              <a
                href="https://twitter.com/rajxidhu"
                target="_blank"
                rel="noreferrer"
              >
                <RiTwitterLine size={"2rem"} />
                Twitter
              </a>
            </div>
          </div>
        </div>
        {/* <form className="contact-container-2" onSubmit={sendEmail} ref={form}>
          <div className="contact-inputboxes">
            <div className="contact-row1">
              <input type="text" placeholder="Name" name="name" required />
              <input
                placeholder="E-mail"
                type="text"
                name="user_email"
                required
              />
              <input placeholder="Subject" type="text" required />
            </div>
            <div className="contact-row2">
              <div className="contact-textarea">
                <textarea placeholder="Message" name="message" required></textarea>
              </div>
            </div>
          </div>
          <div className="contact-submitbtn">
            <RiSendPlane2Line size={"2rem"} />
            <button>Submit</button>
          </div>
        </form>
       */}
      </div>
    </div>
  );
};

export default Contact;
