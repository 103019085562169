import React from "react";
import LeftSideButton from "../leftSideButton/LeftSideButton";
// import Navbar from "../navbar/Navbar";
import "./resume.css";
import { FaHome } from "react-icons/fa";
import ScrollToTop from "../scrollToTop/ScrollToTop";
import resume from "../../assets/data/RajveerSingh.pdf";
import { motion } from "framer-motion";

const Resume = () => {
  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: "-0.1%" }}
      transition={{ duration: 1, ease: "anticipate" }}
      exit={{ opacity: 1 }}
    >
      {/* <Navbar home={"Home"} line={"line-through"} /> */}
      <LeftSideButton
        name={<FaHome size={"2.5rem"} />}
        title="Go back to Home"
        tolink="/"
      />
      <ScrollToTop />
      <div className="resume-hireme-btns">
        <a href={resume} target="_blank" rel="noreferrer">
          <button className="button-50">Download Resume</button>
        </a>
        <a href="mailto:rajveersiidhu@gmail.com">
          <button className="button-50">Hire Me</button>
        </a>
      </div>
      <div className="resume-container">
        <div className="resume-wrapper">
          <h1 className="resume-myname">Rajveer Sidhu</h1>
          <h2 className="resume-top-title">👋 I am a Web Developer.</h2>
          <h2 className="resume-top-title opacity-50">
            Effective at working with both front-end and back-end teams to
            efficiently work towards a responsive, user experience focused
            website. Known by peers as a team-oriented, reliable, and committed
            worker who is able to quickly learn and adapt that would be a
            beneficial addition to any team.
          </h2>
          <div className="socials-btns">
            <a
              href="https://github.com/rajveer-s"
              target="_blank"
              rel="noreferrer"
              className="resume-social-btn"
            >
              Github
            </a>
            <a
              href="https://www.linkedin.com/in/rajveer-s/"
              target="_blank"
              rel="noreferrer"
              className="resume-social-btn"
            >
              LinkedIn
            </a>
            <a
              href="https://twitter.com/rajxidhu"
              target="_blank"
              rel="noreferrer"
              className="resume-social-btn"
            >
              Twitter
            </a>
            <a
              href="mailto:rajveersiidhu@gmail.com"
              target="_blank"
              rel="noreferrer"
              className="resume-social-btn"
            >
              Email
            </a>
          </div>
          {/* experience */}
          <div className="resume-experience">
            {/* Create 2 diffrent experience elements 
            it should have
            *when 
            * full or part time
            * what/job title
            * bullet points
            */}
            <h1 className="resume-heading">Experience</h1>
            <div className="resume-experience-1">
              <div className="resume-experience-time">
                <h2>Jul 2022 - Nov 2022</h2>
                <h3 className="opacity-50">Full Time</h3>
              </div>
              <div className="resume-experience-job">
                <h2>M3F FESTIVAL | FRONTEND DEVELOPER</h2>
                <ul className="resume-experience-bullet-points">
                  <li>
                    Developed and led the M3F Festival project, resulting in
                    over $250,000 in tickets sales in the first month.
                  </li>
                  <li>
                    Worked with project managers and designers to gather and
                    analyze user requirements and translate them into functional
                    and visually appealing web applications, resulting in a 75%
                    increase in user satisfaction.
                  </li>
                  <li>
                    Utilized reusable components and optimized code to improve
                    the maintainability and performance of the websites, leading
                    to a 25% reduction in development time.
                  </li>
                  <li>
                    Implemented responsive design principles to ensure that the
                    websites were fully optimized for mobile and desktop
                    devices, driving a 45% increase in website traffic.
                  </li>
                  <li>
                    Utilized Git version control to manage and track changes to
                    the codebase, resulting in a seamless and organized
                    development process.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Projects */}
          <div className="resume-projects">
            {/* Create 3 diffrent project elements 
            it should have
            * What
            *when
            * bullet points
            * Link
            */}

            {/* Project 1 */}
            <h1 className="resume-heading">Projects</h1>
            <div className="resume-experience-1">
              <div className="resume-experience-time">
                <h2>ARE WE THERE YET</h2>
                <div className="resume-project-links">
                  <a
                    href="https://github.com/rajveer-s/Are_We_There_Yet_Adventure_App"
                    target="_blank"
                    rel="noreferrer"
                    className="opacity-50"
                  >
                    Github Link
                  </a>
                  <a
                    href="https://xnd0.github.io/Are_We_There_Yet_Adventure_App/"
                    target="_blank"
                    rel="noreferrer"
                    className="opacity-50"
                  >
                    Deployed Link
                  </a>
                </div>
              </div>
              <div className="resume-experience-job">
                <h2>
                  HTML, CSS, JavaScript, Utilized National Park API and Google
                  API.
                </h2>
                <ul className="resume-experience-bullet-points">
                  <li>
                    {" "}
                    Led a team of 4 to build a search for adventure application
                    and implement third party National State Park and Maps APIs.
                  </li>
                  <li>
                    Responsible for using Scrum to set goal to work towards
                    within a time limit.
                  </li>
                </ul>
              </div>
            </div>

            {/* Project 2 */}
            <div className="resume-experience-1">
              <div className="resume-experience-time">
                <h2>GET OUTSIDE</h2>
                <div className="resume-project-links">
                  <a
                    href="https://github.com/rajveer-s/get-outside"
                    target="_blank"
                    rel="noreferrer"
                    className="opacity-50"
                  >
                    Github Link
                  </a>
                  <a
                    href="https://getoutside.onrender.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="opacity-50"
                  >
                    Deployed Link
                  </a>
                </div>
              </div>
              <div className="resume-experience-job">
                <h2>
                  MERN Stack, GraphQL with Apollo Server, JSONWebToken, Bcrypt
                </h2>
                <ul className="resume-experience-bullet-points">
                  <li>
                    Led a team to develop, test and deploy a full stack blog
                    site. Created with both user and admin functionality.
                  </li>
                  <li>
                    Reduced the finish time of project by 60% using Github
                    Kanban and keeping everyone on schedule.
                  </li>
                </ul>
              </div>
            </div>

            {/* Project 3 */}
            <div className="resume-experience-1">
              <div className="resume-experience-time">
                <h2>KEY CHANGE</h2>
                <div className="resume-project-links">
                  <a
                    href="https://github.com/rajveer-s/Key-Change"
                    target="_blank"
                    rel="noreferrer"
                    className="opacity-50"
                  >
                    Github Link
                  </a>
                  <a
                    href="https://keychange.onrender.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="opacity-50"
                  >
                    Deployed Link
                  </a>
                </div>
              </div>
              <div className="resume-experience-job">
                <h2>
                  MVC, Dotenv, Handlebars.js, MYSQL, Sequelize, Restful API
                  Routing
                </h2>
                <ul className="resume-experience-bullet-points">
                  <li>
                    Utilized MySQL to design and manage the database for the
                    centralized hub for musicians.
                  </li>
                  <li>
                    Implemented Handlebars.js for the dynamic and user-friendly
                    layout of the platform.
                  </li>
                  <li>
                    Built out the RESTful API routes using Express.js for
                    efficient data manipulation and management.
                  </li>
                  <li>
                    Used Bcrypt for secure user authentication and password
                    encryption.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Education  
          it should have
            *where
            * when
            * what 
          */}
          <div className="resume-education">
            <h1 className="resume-heading">Education</h1>
            <div className="resume-education-content">
              <h2>University of California, Los Angeles extension</h2>
              <h3 className="opacity-50">Feb 2022 - May 2022</h3>
            </div>
          </div>
          {/* Skills  
          it should have
            *Languages
            * Frameworks
            * Designs
          */}
          <div className="resume-skills">
            <h1 className="resume-heading">Skills</h1>
            <div className="reusme-skills-content">
              <div className="resume-skills-laguages">
                <h2>Programming Languages</h2>
                <ul className="resume-skills-bullet-points">
                  <li>JavaScript</li>
                  <li>SQL</li>
                  <li>HTML</li>
                  <li>CSS</li>
                  <li>JSON</li>
                  <li>Markdown</li>
                  <li>RestAPI</li>
                  <li>GraphQL</li>
                </ul>
              </div>
              <div className="resume-skills-laguages">
                <h2>Libraries / Frameworks</h2>
                <ul className="resume-skills-bullet-points">
                  <li>Handlebars</li>
                  <li>Reactjs</li>
                  <li>Node.js</li>
                  <li>Sequelize</li>
                  <li>Express.js</li>
                  <li>JQuery</li>
                  <li>Bootstrap</li>
                </ul>
              </div>
              <div className="resume-skills-laguages">
                <h2>Databases/Tools</h2>
                <ul className="resume-skills-bullet-points">
                  <li>MySQL</li>
                  <li>MongoDB</li>
                  <li>NOSQL</li>
                  <li>Git</li>
                  <li>Netlify</li>
                  <li>Heroku</li>
                  <li>Gitlab</li>
                  <li>Github</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Resume;
